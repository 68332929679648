<template lang="pug">
  include ../../../configs/template
  v-dialog(
    v-model="dialog"
    width="570"
  )
    template(#activator="{ on: dialog, attrs }")
      v-tooltip(top)
        template(#activator="{ on: tooltip }")
          v-btn.ml-5(
            outlined
            fab
            small
            dark
            color="primary"
            v-bind="attrs"
            v-on="{  ...tooltip, ...dialog }")
            v-icon(color="primary") mdi-pen
        span {{$t('tooltip.editMainPhone')}}
    v-card
      v-card-title {{ $t('editMainPhone')}}
      v-card-text
        div.w-100
          PhoneNumberField(v-model="phone_number" :defaultValue="sailorMainPhone" :errorNumber="errorNumber" :error-messages="$valid.validation($v.phone_number, ['required'])").mb-6
      v-card-actions.d-flex.justify-end.px-2.pb-2
        v-btn(color="success" @click="handleClickChangedPhoneNumber").mr-2 {{$t('save')}}
        v-btn(color="secondary" @click="dialog=false") {{$t('cancel')}}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { changeMainPhoneNumber } from '@/mixins/validationRules'
import { replaceAllSymbols } from '@/mixins/main'

export default {
  props: {
    sailorMainPhone: { type: String, default: '' },
    codeCountry: { type: String, default: '' }
  },
  components: {
    PhoneNumberField: () => import('@/components/atoms/PhoneNumberField')
  },

  data () {
    return {
      dialog: false,
      phone_number: null,
      old_phone_number: null,
      errorNumber: false
    }
  },
  computed: {
    ...mapState({
      mappingCountry: state => state.directory.country
    })
  },
  validations () { return changeMainPhoneNumber(this) },
  mounted () {
  },
  methods: {
    ...mapActions(['setSailorMainPhone']),
    handleClickChangedPhoneNumber () {
      this.phone_number = replaceAllSymbols(`${this.phone_number}`, [' ', '(', ')', '-'])
      if (this.$v.$invalid) return this.$v.$touch()
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('confirmChangedMainPhoneNumber'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      }).then(async (confirmation) => {
        if (confirmation) {
          const response = await this.setSailorMainPhone({ phone_number: this.phone_number })
          if (response) {
            this.dialog = false
            this.phone_number = null
            this.$v.$reset()
          } else this.errorNumber = response
        }
      })
    }
  }
}
</script>
